require('./bootstrap');
import './dashboard.js';
window.$ = require('jquery');
global.$ = global.jQuery = require('jquery');
import './common.js';

window.debounce = require("debounce");

require("parsleyjs");
require("jquery.dirty");
window.Swal = require("sweetalert2");
window.select2 = require("select2");
// dt = require('datatables.net');
